import React from "react";

import "../index.css";

import HomeProject from "../page-elements/HomeProject";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { StickyContainer, Sticky } from "react-sticky";
import HomeDoubleProject from "../page-elements/HomeDoubleProject";
import Publications from "../page-elements/Publications";

function Home(props) {
    const mobile = () => {
        return (
            ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod", "Android"].includes(navigator.platform) ||
            // iPad on iOS 13 detection
            (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        );
    };
    return (
        <div>
            <title>Benjamin Lovell</title>
            {props.topbar ? <div className="topbar-spacer"></div> : <div className="sidebar-spacer"></div>}
            <div className="home-header">
            Hey, welcome to my online portfolio! Below are some of my favourite software and hardware projects from over the past few years. I'm currently coding this website, so I haven't managed to upload all my work just yet,
            but hopefully you can get a flavour of my skills!
            </div>

            <h1>PROJECTS</h1>

            <HomeProject
                slug="foto"
                name="Tempo"
                desc=" is a novel and less-intrusive form of digital navigation through binaural spatial audio (Software)."
                url="/tempo"
                topbar={props.topbar}
            />

            <HomeProject
                slug="opaque"
                name="OURA Rise"
                desc=" is a novel IOT system helping detect, monitor and eleviate sleep paralysis episodes (Hardware + Software)."
                url="/ourarise"
                topbar={props.topbar}
                video1
            />


            <HomeProject
                slug="dora"
                name="Dora"
                desc=" is a social app I founded and developed, targeting students and hospitality venues in London (Software)."
                url="https://joindora.framer.website/"
                topbar={props.topbar}
                video2
            />


            <HomeProject
                slug="nova"
                name="Fold"
                desc=" is a portable record player that uses vibrational dampening to maintain a high quality listening experience (Hardware)."
                url="/fold"
                topbar={props.topbar}
            />



            <h1>ABOUT ME</h1>
            <div className="home-viewmore"></div>
            <div style={{ height: "0px" }} id="about"></div>
            <Row className="project-row">
                <Col xs={5} md={5} lg={3} className="project-col" style={{ textAlign: "center" }}>
                    <Image className="mba about-image" fluid src={`./media/about/profile.jpg`} alt=" " />
                </Col>
                <Col xs={12} md={7} lg={9} className="project-col">
                    <div className="mb10 jt">
                        Hello! I'm a recently graduated Design Engineer from Imperial College London, currently seeking product management roles within the technology industry.
                        Throughout my university course, I have developed a range of interdisciplinary skills, ranging from industrial design to full-stack software development. 
                        For more information check out my 
                        {" "}
                        <a href="https://drive.google.com/file/d/1BOHKikJrpRXDVUisobqJNxwyhNxCukVd/view?usp=sharing" target="_blank">
                             CV
                        </a>{" "}
                        and 
                        {" "}
                        <a href="https://drive.google.com/file/d/1XfWnQ6EQCnAJkIZoO7cKbZyLXRlmjH16/view?usp=sharing" target="_blank">
                             PDF Portfolio.
                        </a>{" "}
                    </div>
                    <h2 className="mta mb10 jt">Contact:</h2>
                    <div className="">
                        <div className="fr">
                            {/* <svg
                                version="1.1"
                                style={{ maxWidth: mobile() ? 20 : 32, transform: "translateY(50%)", marginRight: 10 }}
                                id="line_2"
                                x="0px"
                                y="0px"
                                width="100%"
                                height="20"
                                xmlSpace="preserve"
                            >
                                <path class="path2" fill="#1d1d1d" stroke-width="3" stroke="#1d1d1d" d="M0 0 l120 0" />
                            </svg> */}
                            <div className="jt">
                                Email: <a href="mailto:benlovelldesign@gmail.com">benlovelldesign@gmail.com</a>
                            </div>
                        </div>
                        <div className="fr">
                            {/* <svg
                                version="1.1"
                                style={{ maxWidth: mobile() ? 20 : 32, transform: "translateY(50%)", marginRight: 10 }}
                                id="line_2"
                                x="0px"
                                y="0px"
                                width="100%"
                                height="20"
                                xmlSpace="preserve"
                            >
                                <path class="path2" fill="#1d1d1d" stroke-width="3" stroke="#1d1d1d" d="M0 0 l120 0" />
                            </svg> */}
                            <div className="jt">Phone: +447594465070</div>
                        </div>
                    </div>
                </Col>
            </Row>
            <div class="bottom-spacer"></div>
        </div>
    );
}

export default Home;
